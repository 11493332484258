import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import App from 'next/app'
import Head from 'next/head'

export default class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props
    return (
      <ChakraProvider>
        <Head>
          <title>Code Snippets</title>
          <meta name="description" content="Code Snippets for any programming language" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Component {...pageProps} />
      </ChakraProvider>
    )
  }
}
